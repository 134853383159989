<template>
  <div class="weekRunTime">
    <div class="bigCircle" v-if="weekPerfrom">
      <div v-for="(item, index) in statuslist" :key="item.className" :class="index === statusindex ? item.activeClass : item.className"></div>
      <div class="smallCircle">
        <div class="name" :class="statuslist[statusindex].textClass">{{ statuslist[statusindex].name }}</div>
        <div class="statusNum" :class="statuslist[statusindex].textClass">{{ `${(weekPerfrom[statuslist[statusindex].timerCode] / 60 / 1000 / 60).toFixed(1) || 0}小时` }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
      statusindex: 0,
      statuslist: [
        { name: '运行时长', className: 'run', activeClass: 'runActive', textClass: 'runtext', timerCode: 'runningTime' },
        { name: '离线时长', className: 'off', activeClass: 'offActive', textClass: 'offtext', timerCode: 'offlineTime' },
        { name: '故障时长', className: 'alarm', activeClass: 'alarmActive', textClass: 'alarmtext', timerCode: 'alarmTime' },
        { name: '空闲时长', className: 'free', activeClass: 'freeActive', textClass: 'freetext', timerCode: 'freeTime' }
      ]
    };
  },
  props: {
    weekPerfrom: {
      type: Object
    }
  },
  created() {
    this.timer = setInterval(() => {
      this.statusindex++;
      if (this.statusindex > 3) {
        this.statusindex = 0;
      }
    }, 10 * 1000);
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.weekRunTime {
  height: 200px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../image/border.png') no-repeat;
  background-position: 50% 50%;
}
.bigCircle {
  position: relative;
  width: 158px;
  height: 158px;
  border-radius: 50%;
  border: 1px dotted rgba(67, 186, 254, 0.5);
}
.run {
  position: absolute;
  right: 33%;
  top: -25px;
  width: 48px;
  height: 48px;
  background: url('../image/run_none.png') no-repeat;
}
.runActive {
  position: absolute;
  right: 33%;
  top: -25px;
  width: 48px;
  height: 48px;
  background: url('../image/run_active.png') no-repeat;
}
.off {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  background: url('../image/off_none.png') no-repeat;
}
.offActive {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  background: url('../image/off_active.png') no-repeat;
}
.alarm {
  position: absolute;
  right: 32%;
  bottom: -30px;
  width: 48px;
  height: 48px;
  background: url('../image/alarm_none.png') no-repeat;
}
.alarmActive {
  position: absolute;
  right: 32%;
  bottom: -30px;
  width: 48px;
  height: 48px;
  background: url('../image/alarm_active.png') no-repeat;
}
.free {
  position: absolute;
  left: -24px;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  background: url('../image/free_none.png') no-repeat;
}
.freeActive {
  position: absolute;
  left: -24px;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  background: url('../image/free_active.png') no-repeat;
}
.smallCircle {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 104px;
  height: 104px;
  border: 1px solid #1bb1bd;
  border-radius: 50%;
}
.runtext {
  color: #5ebb38;
}
.offtext {
  color: #7e7e7e;
}
.alarmtext {
  color: #e43737;
}
.freetext {
  color: #f39800;
}
.statusNum {
  font-size: 16px;
  font-family: MyFontName;
}
</style>
