<template>
  <div class="MapShow" :style="`background:${backgroundColor}`">
    <Mapheader :MapData="MapData" :isMapShow="true">
      <div class="changeBtn">
        <i v-if="!is3D" class="iconfont icon-dca-3Dmoxing" @click="changeModel"></i>
        <i v-else class="iconfont icon-dca-2Dmoxing" @click="changeModel"></i>
      </div>
    </Mapheader>
    <Mapshow :is3D="is3D" :MapData="MapData" />
    <MapOee />
  </div>
</template>

<script>
import Mapheader from '../components/header';
import Mapshow from '../components/Mapshow';
import MapOee from '../components/Mapoee';
export default {
  data() {
    return {
      backgroundColor: '#0a161b',
      MapData: null,
      is3D: false
    };
  },
  components: {
    Mapheader,
    Mapshow,
    MapOee
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const tenantId = JSON.parse(localStorage.getItem('user')).tenantId;
      const res = await this.$apis.Map3d.get({ tenantId });
      this.MapData = res;
      this.backgroundColor = this.MapData.backgrColor;
      this.modelColor = this.MapData.moderlColor;
    },
    changeModel() {
      this.is3D = !this.is3D;
    }
  }
};
</script>

<style lang="scss" scoped>
.MapShow {
  width: 100vw;
  height: 100vh;
  background-color: #0a161b;
}
.changeBtn {
  position: absolute;
  right: 40px;
  top: 62px;
  .iconfont {
    font-size: 44px;
    cursor: pointer;
  }
}
</style>
