<template>
  <div class="Mapshow" v-if="MapData">
    <Device class="Map2D" :device_ago="deivceStatus" :MapData="MapData" :background="MapData.backgrAddress" v-if="!is3D" />
    <div class="infos" v-show="!is3D">
      <i class="iconfont icon-dcicon_jt_right_nor" @click="showStatus"></i>
      <Mapdrawer ref="oee" :MapData="MapData" @open="showStatus" />
    </div>
    <ThreeModel v-show="is3D" :backgroundColor="backgroundColor" :modelColor="modelColor" :MapData="MapData" />
  </div>
</template>

<script>
import Base64 from '../../base64.js';
import Device from './device_info';
import ThreeModel from '../ThreeModel';
import Mapdrawer from './device_info/oee';
export default {
  props: {
    is3D: {
      type: Boolean
    },
    MapData: {
      type: Object
      // required: true
    }
  },
  components: { Device, Mapdrawer, ThreeModel },
  data() {
    return {
      deivceStatus: [],
      modelColor: '',
      backgroundColor: ''
    };
  },
  watch: {
    MapData() {
      this.modelColor = this.MapData.moderlColor;
      this.backgroundColor = this.MapData.backgrColor;
      if (this.MapData.mapContent) {
        this.deivceStatus = JSON.parse(Base64.decode(this.MapData.mapContent));
      }
    },
    deep: true
  },
  methods: {
    showStatus() {
      this.$refs.oee.show();
    }
  }
};
</script>

<style lang="scss" scoped>
.Mapshow {
  height: 685px;
  width: 100%;
  background-size: contain !important;
  background-position: center !important;
}
.Map2D {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: contain !important;
  background-position: center !important;
}
.infos {
  .icon-dcicon_jt_right_nor {
    display: inline-block;
    color: rgb(136, 136, 136);
    border-radius: 3px;
    background: rgba(18, 18, 18, 0.65);
    position: fixed;
    top: 50%;
    left: -2px;
    cursor: pointer;
    z-index: 1;
    &:hover {
      color: #f39800;
    }
  }
}
</style>
