<template>
  <div class="drawer" v-show="visible">
    <DrawerTitle :name="`7日绩效总览`" />
    <weekRunTime :weekPerfrom="weekPerfrom" />
    <perfromProgress :weekPerfrom="weekPerfrom" />
    <DrawerTitle :name="`设备异常信息`" />
    <el-table :data="tableData" max-height="540" style="margin-top: 10px background: #15282f;" :row-class-name="tableRowClassName">
      <el-table-column type="index" label="序号"> </el-table-column>
      <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
      <el-table-column width="180" prop="exceptionTime" label="报警时间"> </el-table-column>
    </el-table>
    <i class="close iconfont icon-dcicon_jt_left" @click="closeStatus"></i>
  </div>
</template>

<script>
import DrawerTitle from '../components/drawerTitle.vue';
import weekRunTime from '../components/weekRunTime.vue';
import perfromProgress from '../components/perfromProgress.vue';
export default {
  data() {
    return {
      visible: false,
      tableData: [],
      weekPerfrom: {},
      timer: null
    };
  },
  props: {
    MapData: {
      type: Object
    }
  },
  components: {
    DrawerTitle,
    weekRunTime,
    perfromProgress
  },
  mounted() {
    this.$emit('open');
  },
  methods: {
    async show() {
      this.visible = true;
      const res = await this.$apis.Map3d.AlarmDevice({ bindDevices: this.MapData.bindDevices });
      this.tableData = res;
      const weekPerfrom = await this.$apis.Map3d.oee({ bindDevices: this.MapData.bindDevices });
      this.weekPerfrom = weekPerfrom;
      this.timer = setInterval(async () => {
        const res = await this.$apis.Map3d.AlarmDevice({ bindDevices: this.MapData.bindDevices });
        this.tableData = res;
      }, 30 * 1000);
    },
    closeStatus() {
      this.visible = false;
      clearInterval(this.timer);
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'even';
      } else {
        return 'odd';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.drawer {
  position: absolute;
  z-index: 6;
  width: 360px;
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  top: 0;
  color: #fff;
  background: #060606;
  opacity: 0.8;
  overflow: inherit;
}

.close {
  position: absolute;
  left: 358px;
  top: 50%;
  width: 16px;
  font-size: 16px;
  display: inline-block;
  color: rgb(136, 136, 136);
  border-radius: 3px;
  background: rgba(18, 18, 18, 0.65);
  position: fixed;
  cursor: pointer;
  z-index: 1;
  &:hover {
    color: #f39800;
  }
}

/deep/ .even {
  background: #0b171c;
  color: #fff;
  height: 40px !important;
  border: 1px solid transparent;
}
/deep/ th {
  border-bottom: 0px solid #fff !important;
  color: #fff;
}
/deep/ .el-table td.el-table__cell {
  border-bottom: 0px solid #fff !important;
}
/deep/.el-table th.el-table__cell.is-leaf {
  border-bottom: 0px solid #fff !important;
}
/deep/ .odd {
  background: #15282f;
  color: #fff;
  height: 40px !important;
  border: 1px solid transparent;
}

/deep/ .cell {
  max-height: 23px !important;
  overflow: hidden !important;
}
/deep/ .el-table thead th.el-table__cell,
.headerClassName,
.headerClassName > th {
  background-color: #15282f !important;
}
/deep/ .el-table--group::after,
.el-table--border::after,
.el-table::before {
  background-color: #15282f;
}
/deep/ .el-table__empty-block {
  background-color: #15282f;
}
/deep/ .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: transparent;
}
/deep/ .el-table--scrollable-y .el-table__body-wrapper {
  overflow: hidden;
}
</style>
