<template>
  <div class="charts" :style="`background:url(${backgroundUrl}) no-repeat`">
    <ul class="device-list">
      <li class="device-item" v-for="item in device_List" :key="item.deviceId" :style="deviceStyle(item, false)" @click="showDeviceInfos(item)">
        <div class="device-dialog" :style="deviceStyle(item, true)">
          <div class="device">
            <div :class="`${item.status}`"></div>
            <div class="device-detail">
              <div
                class="status"
                :style="`color:${item.status === 'RUNNING_STATE' ? '#19A572' : item.status === 'FREE_STATE' ? '#f39800' : item.status === 'OFFLINE_STATE' ? '#909399' : '#EA3837'} ; font-family: blod;`"
              >
                {{ item.status === 'RUNNING_STATE' ? '运行中' : item.status === 'FREE_STATE' ? '空闲' : item.status === 'OFFLINE_STATE' ? '离线' : '故障' }}
              </div>
              <div class="name" :title="item.deviceName">{{ item.deviceName }}</div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <DeviceInfo ref="info" />
  </div>
</template>

<script>
import DeviceInfo from './info.vue';
export default {
  components: { DeviceInfo },
  props: {
    device_ago: {
      type: Array,
      default: () => []
    },
    background: {
      type: String
    }
  },
  data() {
    return {
      device_List: [],
      deviceIds: []
    };
  },
  created() {
    this.init();

    this.backgroundUrl = process.env.VUE_APP_API_BASE_URL + this.background;

    setInterval(() => {
      this.init();
    }, 10 * 1000);
  },
  watch: {
    device_ago() {
      this.init();
    },
    background() {
      this.backgroundUrl = process.env.VUE_APP_API_BASE_URL + this.background;
    }
  },
  methods: {
    async init() {
      this.deviceIds = [];
      if (!this.device_ago.length) {
        return false;
      }
      this.device_ago.forEach(item => {
        this.deviceIds.push(item.deviceId);
      });
      const newDevice = JSON.parse(JSON.stringify(this.device_ago));
      const res = await this.$apis.longjing.DeviceStatus({ deviceIds: this.deviceIds });
      newDevice.forEach(item => {
        res.forEach(ele => {
          if (item.deviceId === ele.id) {
            item.deviceName = ele.name;
            item.status = ele.runningState;
          }
        });
      });
      this.device_List = newDevice;
    },
    deviceStyle({ position, size }, isdialog) {
      if (isdialog) {
        return {
          width: `136px`,
          height: `91px`,
          left: `${size.width / 2 - 66}px`,
          top: `${size.height / 2 - 91}px`
        };
      } else {
        return {
          width: `${size.width}px`,
          height: `${size.height}px`,
          left: `${position.x}px`,
          top: `${position.y}px`
        };
      }
    },
    async showDeviceInfos(item) {
      this.$refs.info.show(item);
    }
  }
};
</script>

<style lang="scss" scoped>
.device-item {
  position: absolute;
  cursor: pointer;
}
.device-dialog {
  position: absolute;
  background: url('../image/dialog.png') no-repeat;
  .device {
    width: 136px;
    height: 60px;
    padding: 10px 5px;
    display: flex;
    .RUNNING_STATE {
      width: 26px;
      height: 36px;
      background: url('../image/green.png') no-repeat center/cover;
    }
    .FREE_STATE {
      width: 26px;
      height: 36px;
      background: url('../image/orange.png') no-repeat center/cover;
    }
    .OFFLINE_STATE {
      width: 26px;
      height: 36px;
      background: url('../image/grey.png') no-repeat center/cover;
    }
    .ALARM_STATE {
      width: 26px;
      height: 36px;
      background: url('../image/red.png') no-repeat center/cover;
    }
    .device-detail {
      flex: 1;
      .status {
        font-style: 16px;
        font-weight: bold;
      }
      .name {
        font-family: stand;
        color: #fff;
        font-style: 14px;
        overflow: hidden;
        width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
